
import { Vue } from "vue-class-component";
import { whiteLabel } from "@cems-eco/core/src/utils";
import guard from "@/guard";

export default class App extends Vue {
  default = "main";

  get layout() {
    return (this.$route.meta.layout || this.default) + "-layout";
  }

  async created() {    
    whiteLabel.setup();
    await guard.checkProviderStatus();
  }
}
