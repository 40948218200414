import { api } from '@cems-eco/core/src/services/api';
import { Service } from "typedi";
import Container from "typedi";

@Service()
class AuthApi {
    private BASE_URL: string;

    constructor() {
        this.BASE_URL = "/auth/login";
    }

    async login(data: any): Promise<any> {
        return await api.post<any>(this.BASE_URL, data, true);
    }

    async site(id): Promise<any> {
        return await api.post<any>("/auth/site", { id }, true);
    }
}

export default Container.get(AuthApi);
