export default {
    "exactanalytical.com.my": {
          template: "argon",
          asset: {
              logo: "/asset/exact/logo.png",
              favicon: "/asset/exact/favicon.png",
              favicon_16: "/asset/exact/favicon-16x16.png",
              favicon_32: "/asset/exact/favicon-32x32.png",
              apple_touch_icon: "/asset/exact/apple-touch-icon.png",
              manifest: "/asset/exact/site.webmanifest",
          },
          project: {
              name: `CEMS`,
              client: "CEMS",
              client_url: "https://www.exactanalytical.com.my/solutions-2/cems/",
              author: "Exact Analytical",
              author_url: "https://www.exactanalytical.com.my/"
          }
      }
  }