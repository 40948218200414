import { defineModuleProvider } from "@cems-eco/core/src/utils/module";

export default defineModuleProvider({
    name: "user",
    routes: [
        {
            path: '/user',
            name: 'user',
            meta: { layout: "main", requiresAuth: true, breadcrumb: 'User', menuClass: "ni ni-single-02 text-primary" },
            component: () => import(/* webpackChunkName: "user" */ './pages/base.vue'),
            children: [
                {
                    path: '',
                    name: 'user.index',
                    meta: { layout: "main", requiresAuth: true, parent: true, parentPath: '/user', parentName: 'User', breadcrumb: 'User', permissionName: 'View User' },
                    component: () => import(/* webpackChunkName: "user.index" */ './pages/index.vue'),
                },
                {
                    path: ':id',
                    name: 'user.view',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/user', parentName: 'User', breadcrumb: 'View', permissionName: 'View User' },
                    component: () => import(/* webpackChunkName: "user.view" */ './pages/view.vue'),
                },
                {
                    path: 'create',
                    name: 'user.create',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/user', parentName: 'User', breadcrumb: 'Create', permissionName: 'Create User' },
                    component: () => import(/* webpackChunkName: "user.create" */ './pages/create.vue'),
                },
                {
                    path: ':id/edit',
                    name: 'user.edit',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/user', parentName: 'User', breadcrumb: 'Edit', permissionName: 'Edit User' },
                    component: () => import(/* webpackChunkName: "user.edit" */ './pages/edit.vue'),
                },
            ]
        }
    ],
    menu: [
        {
            id: "user",
            class: "ni ni-single-02 text-primary",
            type: "single",
            name: "User",
            route: "/user",
        },
    ]
});