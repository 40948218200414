import AuthProvider from "@/modules/auth/provider";
import ClientProvider from "@/modules/client/provider";
import SiteProvider from "@/modules/site/provider";
import EquipmentProvider from "@/modules/equipment/provider";
import SensorProvider from "@/modules/sensor/provider";
import UserProvider from "@/modules/user/provider";
import ConfigurationProvider from "@/modules/configuration/provider";
import ActivityProvider from "@/modules/activity/provider";
import DashboardProvider from "@/modules/dashboard/provider";
import BackupProvider from "@/modules/backup/provider";
import type { ModuleProvider } from "@cems-eco/core/src/utils/module";

export default [
    AuthProvider,
    ClientProvider,
    SiteProvider,
    EquipmentProvider,
    SensorProvider,
    UserProvider,
    ConfigurationProvider,
    ActivityProvider,
    DashboardProvider,
    BackupProvider
] as ModuleProvider[];
