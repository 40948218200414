import { api } from '@cems-eco/core/src/services/api';
import { Service } from "typedi";
import Container from "typedi";

@Service()
export class TopMenuApi {

    
    constructor() {
    }

    async getSite(id: any): Promise<any> {
        return await api.get<any>(`/site/${id}`, true);
    }


    async logout(data: any): Promise<any> {
        return await api.post<any>(`/activity/logout`, data, true);
    }

}

export default Container.get(TopMenuApi);