import { defineModuleProvider } from "@cems-eco/core/src/utils/module";

export default defineModuleProvider({
    name: "sensor",
    routes: [
        {
            path: '/site',
            name: 'site',
            meta: { layout: "main", requiresAuth: true, breadcrumb: 'Site', menuClass: "ni ni-building text-primary" },
            component: () => import(/* webpackChunkName: "site" */ './pages/base.vue'),
            children: [
                {
                    path: '',
                    name: 'site.index',
                    meta: { layout: "main", requiresAuth: true, parent: true, parentPath: '/site', parentName: 'Site', breadcrumb: 'Site', permissionName: 'View Site' },
                    component: () => import(/* webpackChunkName: "site.index" */ './pages/index.vue'),
                },
                {
                    path: ':id',
                    name: 'site.view',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/site', parentName: 'Site', breadcrumb: 'View', permissionName: 'View Site' },
                    component: () => import(/* webpackChunkName: "site.view" */ './pages/view.vue'),
                },
                {
                    path: 'create',
                    name: 'site.create',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/site', parentName: 'Site', breadcrumb: 'Create', permissionName: 'Create Site' },
                    component: () => import(/* webpackChunkName: "site.create" */ './pages/create.vue'),
                    children: [
                        {
                            path: '',
                            name: 'site.create',
                            meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/site', parentName: 'Site', breadcrumb: 'Create', permissionName: 'Create Site' },
                            component: () => import(/* webpackChunkName: "site.create" */ './pages/create.vue'),
                        },
                        {
                            path: ':id',
                            name: 'site.create',
                            meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/site', parentName: 'Site', breadcrumb: 'Create', permissionName: 'Create Site' },
                            component: () => import(/* webpackChunkName: "site.create" */ './pages/create.vue'),
                        },
                    ]
                },
                {
                    path: ':id/edit',
                    name: 'site.edit',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/site', parentName: 'Site', breadcrumb: 'Edit', permissionName: 'Edit Site' },
                    component: () => import(/* webpackChunkName: "site.edit" */ './pages/edit.vue'),
                },
            ]
        }

    ],
    menu: [
        {
            id: "site",
            class: "ni ni-building text-primary",
            type: "single",
            name: "Site",
            route: "/site",
        },
    ]
});