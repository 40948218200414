import { Login } from "./index";
import api from "./api";
import storage from "@/storage";
import { IAccessStorage, IAuthStorage, IClientStorage, ISiteStorage, IUserStorage, ILoginStorage } from '@/storage/model';
import { isEmpty } from "lodash";
import { Service } from "typedi";
import Container from "typedi";

@Service()
class AuthBloc {
    module = 'auth';

    async login(login: Login): Promise<any> {
        let response = await api.login(login)
        return response.data;
    }

    async site(id): Promise<any> {
        const site_auths = await storage.get<IAuthStorage>(storage.SITE_AUTHS);
        let auth: IAuthStorage;
        let doLogin = false;
        let auths: any = {};
        if (isEmpty(site_auths)) {
            doLogin = true;
        } else {
            auths = site_auths;
            if (isEmpty(site_auths[id])) {
                doLogin = true;
            }
        }

        if (doLogin) {
            let response = await api.site(id);
            auth = response.data.auth;
            auths[id] = response.data.auth;
            await storage.set(storage.SITE_AUTHS, auths);
        } else {
            auth = site_auths[id];
        }
        await storage.set<IAuthStorage>(storage.SITE_AUTH, auth);
    }

    async saveStorage(data: any): Promise<any> {
        const auth: IAuthStorage = data.auth;
        await storage.set<IAuthStorage>(storage.AUTH, auth);

        const access: IAccessStorage = data.configuration.access;
        await storage.set<IAccessStorage>(storage.ACCESS, access);

        const client: IClientStorage = data.configuration.selected_client;
        await storage.set<IClientStorage>(storage.CLIENT, client);

        const site: ISiteStorage = data.configuration.selected_site;
        await storage.set<ISiteStorage>(storage.SITE, site);

        const permission: string[] = data.configuration.user_permission;
        await storage.set<string[]>(storage.PERMISSION, permission);

        const user: IUserStorage = data.user;
        await storage.set<IUserStorage>(storage.USER, user);

        const loginToken: ILoginStorage = data.login_token;
        await storage.set<ILoginStorage>(storage.LOGIN_TOKEN, loginToken);

        const alarm = true;
        await storage.set<any>(storage.ALARM, alarm);
    }
}

export default Container.get(AuthBloc);

