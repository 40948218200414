import modules from "@/module";
import { menu } from "@cems-eco/core/src/utils/menu";

menu.setMenu(modules);

export default [
  menu.getDivider(),
  menu.getHeader("MENU"),
  menu.getMenu("dashboard"),
  menu.getMenu("client"),
  menu.getMenu("site"),
  menu.getMenu("equipment"),
  menu.getMenu("sensor"),
  menu.getMenu("user"),
  menu.getDivider(),
  menu.getHeader("SYSTEM"),
  menu.getMenu("configuration"),
  menu.getMenu("activity"),
  menu.getMenu("backup"),
];