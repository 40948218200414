import { defineModuleProvider } from "@cems-eco/core/src/utils/module";
import guard from "@/guard";

export default defineModuleProvider({
    name: "configuration",
    routes: [
        {
            path: '/configuration',
            name: 'configuration',
            meta: { layout: "main", requiresAuth: true, breadcrumb: 'Configuration', menuClass: "fas fa-cog text-primary" },
            component: () => import(/* webpackChunkName: "configuration" */ './pages/base.vue'),
            children: [
                {
                    path: '',
                    name: 'configuration.index',
                    meta: { layout: "main", requiresAuth: true, parent: true, parentPath: '/configuration', parentName: 'Configuration', breadcrumb: 'Configuration', permissionName: 'View Configuration' },
                    component: () => import(/* webpackChunkName: "configuration.index" */ './pages/index.vue'),
                },
                {
                    path: ':id',
                    name: 'configuration.view',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/configuration', parentName: 'Configuration', breadcrumb: 'View', permissionName: 'View Configuration' },
                    component: () => import(/* webpackChunkName: "configuration.view" */ './pages/view.vue'),
                },
                {
                    path: 'create',
                    name: 'configuration.create',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/configuration', parentName: 'Configuration', breadcrumb: 'Create', permissionName: 'Create Configuration' },
                    component: () => import(/* webpackChunkName: "configuration.create" */ './pages/create.vue'),
                },
                {
                    path: ':id/edit',
                    name: 'configuration.edit',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/configuration', parentName: 'Configuration', breadcrumb: 'Edit', permissionName: 'Edit Configuration' },
                    component: () => import(/* webpackChunkName: "configuration.edit" */ './pages/edit.vue'),
                },
            ]
        },
    ],
    menu: [
        {
            id: "configuration",
            class: "fas fa-cog text-primary",
            type: "single",
            name: "Configuration",
            route: "/configuration",
        },
    ]
});