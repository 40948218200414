import { defineModuleProvider } from "@cems-eco/core/src/utils/module";
import guard from "@/guard";

export default defineModuleProvider({
    name: "activity",
    routes: [
        {
            path: '/activity',
            name: 'activity',
            meta: { layout: "main", requiresAuth: true, breadcrumb: 'Activity', menuClass : "fas fa-user-clock text-primary" },
            component: () => import(/* webpackChunkName: "activity" */ './pages/base.vue'),
            children: [
                {
                    path: '',
                    name: 'activity.index',
                    meta: { layout: "main", requiresAuth: true, parent: true, parentPath: '/activity', parentName: 'Activity', breadcrumb: 'Activity', permissionName: 'View Activity' },
                    component: () => import(/* webpackChunkName: "activity.index" */ './pages/index.vue'),
                }
            ]
        },
    ],
    menu: [
        {
            id: "activity",
            class: "fas fa-user-clock text-primary",
            type: "single",
            name: "Activity",
            route: "/activity",
        },
    ]
});