import { defineModuleProvider } from "@cems-eco/core/src/utils/module";

export default defineModuleProvider({
    name: "client",
    routes: [
        {
            path: '/client',
            name: 'client',
            meta: { layout: "main", requiresAuth: true, breadcrumb: 'Client', menuClass: "ni ni-shop text-primary" },
            component: () => import(/* webpackChunkName: "client" */ './pages/base.vue'),
            children: [
                {
                    path: '',
                    name: 'client.index',
                    meta: { layout: "main", requiresAuth: true, parent: true, parentPath: '/client', parentName: 'Client', breadcrumb: 'Client', permissionName: 'View Client' },
                    component: () => import(/* webpackChunkName: "client.index" */ './pages/index.vue'),
                    beforeEnter: function (from, to, next) {
                        const accessModule = "admin";
                        const access = "All Client";
                        const user_access: any = localStorage.getItem("access");
                        // if (user_access[accessModule].includes(JSON.parse(access))) {
                            next();
                        // }
    
                        // popup: you dont have access to view this page
                    }
                },
                {
                    path: ':id',
                    name: 'client.view',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/client', parentName: 'Client', breadcrumb: 'View', permissionName: 'View Client' },
                    component: () => import(/* webpackChunkName: "client.view" */ './pages/view.vue'),
                },
                {
                    path: 'create',
                    name: 'client.create',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/client', parentName: 'Client', breadcrumb: 'Create', permissionName: 'Create Client' },
                    component: () => import(/* webpackChunkName: "client.create" */ './pages/create.vue'),
                },
                {
                    path: ':id/edit',
                    name: 'client.edit',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/client', parentName: 'Client', breadcrumb: 'Edit', permissionName: 'Edit Client' },
                    component: () => import(/* webpackChunkName: "client.edit" */ './pages/edit.vue'),
                },
            ]
        }

    ],
    menu: [
        {
            id: "client",
            class: "ni ni-shop text-primary",
            type: "single",
            name: "Client",
            route: "/client",
        },
    ]
});