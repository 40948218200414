import { Site } from "./index";
import api from "./api";
import { Service } from "typedi";
import Container from "typedi";

@Service()
export class TopMenuBloc {

    async getSite(id: any): Promise<Site> {
        let response = await api.getSite(id)
        return response.data;
    }

    async logout(id: any): Promise<void> {
        let response = await api.logout(id)
        return response.data;
    }

}

export default Container.get(TopMenuBloc);
