import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

import modules from "@/module";
import guard from "@/guard";


let routes: Array<any> = [];

for (const key in modules) {
    modules[key].routes.forEach((route: any) => {
        routes.push(route);
    });
}


routes = routes.map((route) => ({
    ...route,
    children: route.children?.map((r) => ({
        ...r,
        beforeEnter: async (to, from, next) => {
            await guard.checkProviderStatus();
            guard.pageAccess(to, from, next);
        },
    })),
}));

export default createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: (to, from, savedPosition) => savedPosition ?? { top: 0 },
});