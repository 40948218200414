import modules from "@/module";
import { menu } from "@cems-eco/core/src/utils/menu";

menu.setMenu(modules);

export default [
  menu.getMenu("client"),
  menu.getMenu("site"),
  menu.getMenu("dashboard"),
  menu.getMenu("user"),
];