import { defineModuleProvider } from "@cems-eco/core/src/utils/module";
import guard from "@/guard";

export default defineModuleProvider({
    name: "backup",
    routes: [
        {
            path: '/backup',
            name: 'backup',
            meta: { layout: "main", requiresAuth: true, breadcrumb: 'Backup', menuClass : "fas fa-user-clock text-primary" },
            component: () => import(/* webpackChunkName: "backup" */ './pages/base.vue'),
            children: [
                {
                    path: '',
                    name: 'backup.index',
                    meta: { layout: "main", requiresAuth: true, parent: true, parentPath: '/backup', parentName: 'Backup', breadcrumb: 'Backup', permissionName: 'View Backup' },
                    component: () => import(/* webpackChunkName: "backup.index" */ './pages/index.vue'),
                }
            ]
        },
    ],
    menu: [
        {
            id: "backup",
            class: "fas fa-cloud-upload-alt text-primary",
            type: "single",
            name: "Backup",
            route: "/backup",
        },
    ]
});