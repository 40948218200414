
import SideMenu from "./side-menu/side-menu.vue";
import TabMenu from "./tab-menu/tab-menu.vue";

import TopMenu from "./top-menu/top-menu.vue";

export default {
    menu: {
        side: SideMenu,
        top: TopMenu,
        tab: TabMenu,
    },
};