import { defineModuleProvider } from "@cems-eco/core/src/utils/module";

export default defineModuleProvider({
    name: "dashboard",
    routes: [

        {
            path: '/dashboard',
            name: 'Dashboard',
            meta: { layout: "main" },
            component: () => import(/* webpackChunkName: "dummy.monitor" */ './pages/layout.vue'),
            children: [
                {
                    path: '',
                    name: 'dashboard.page',
                    meta: { layout: "main", requiresAuth: true, parent: true, parentPath: '/dashboard', parentName: 'Dashboard', breadcrumb: 'Dashboard' },
                    component: () => import(/* webpackChunkName: "dummy.page" */ './pages/index.vue'),
                },
            ]
        }

    ],
    menu: [
        {
            id: "dashboard",
            class: "ni ni-chart-bar-32 text-primary",
            type: "single",
            name: "Dashboard",
            route: "/dashboard",
        },
    ]
});