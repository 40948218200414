import { defineModuleProvider, platform } from "@cems-eco/core/src/utils";

export default defineModuleProvider({
    name: "auth",
    routes: [
        {
            path: '/',
            redirect: '/login',
        },
        {
            path: '/login',
            name: 'Login',
            meta: { layout: "login" },
            component: () => import(/* webpackChunkName: "auth.login" */ `./pages/login/login.${platform.get()}.vue`),
        }

    ],
    menu: []
});