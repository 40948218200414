import { defineModuleProvider } from "@cems-eco/core/src/utils/module";

export default defineModuleProvider({
    name: "equipment",
    routes: [
        {
            path: '/equipment',
            name: 'equipment',
            meta: { layout: "main", requiresAuth: true, breadcrumb: 'Equipment', menuClass: "ni ni-settings text-primary" },
            component: () => import(/* webpackChunkName: "equipment" */ './pages/base.vue'),
            children: [
                {
                    path: '',
                    name: 'equipment.index',
                    meta: { layout: "main", requiresAuth: true, parent: true, parentPath: '/equipment', parentName: 'Equipment', breadcrumb: 'Equipment', permissionName: 'View Equipment' },
                    component: () => import(/* webpackChunkName: "equipment.index" */ './pages/index.vue'),
                },
                {
                    path: ':id',
                    name: 'equipment.view',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/equipment', parentName: 'Equipment', breadcrumb: 'View', permissionName: 'View Equipment' },
                    component: () => import(/* webpackChunkName: "equipment.view" */ './pages/view.vue'),
                },
                {
                    path: 'create',
                    name: 'equipment.create',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/equipment', parentName: 'Equipment', breadcrumb: 'Create', permissionName: 'Create Equipment' },
                    component: () => import(/* webpackChunkName: "equipment.create" */ './pages/create.vue'),
                    children: [
                        {
                            path: '',
                            name: 'equipment.create',
                            meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/equipment', parentName: 'Equipment', breadcrumb: 'Create', permissionName: 'Create Equipment' },
                            component: () => import(/* webpackChunkName: "equipment.create" */ './pages/create.vue'),
                        },
                        {
                            path: ':id',
                            name: 'equipment.create',
                            meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/equipment', parentName: 'Equipment', breadcrumb: 'Create', permissionName: 'Create Equipment' },
                            component: () => import(/* webpackChunkName: "equipment.create" */ './pages/create.vue'),
                        },
                    ]
                },
                {
                    path: ':id/edit',
                    name: 'equipment.edit',
                    meta: { layout: "main", requiresAuth: true, parent: false, parentPath: '/equipment', parentName: 'Equipment', breadcrumb: 'Edit', permissionName: 'Edit Equipment' },
                    component: () => import(/* webpackChunkName: "equipment.edit" */ './pages/edit.vue'),
                },
            ]
        }
    ],
    menu: [
        {
            id: "equipment",
            class: "ni ni-settings text-primary",
            type: "single",
            name: "Equipment",
            route: "/equipment",
        },
    ]
});